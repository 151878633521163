<template>
    <div class="page-content w-100">
        <div class="row">
          <div class="col-sm-12 mb-3">
            <p class="text-center page-heading">Clinic Operations</p>
          </div>
        </div>
    <div class="mx-3">

  <div class="mobie-setting-mainDiv" >
    <div class="mb-3 col-3 text-center">
            <select class="form-control consultation-select" name="select" id="select" v-model="selected">
        <option value="1">Select Clinic</option>
        <option v-for="clinic in clinicData" :key="clinic" :value="clinic">{{ clinic.short_name? clinic.short_name :clinic.clinic_name }}</option>
    </select>
        </div>
        <div class="my-3" v-if="selected.clinic_name && !selected.short_name">
            For convenience, you may provide a short name for the clinic. 
            It will appear in the dropdown above, instead of the complete clinic name.
        </div>
        <div class="my-3" v-if="selected.clinic_name && !selected.short_name">
            <label >Short name for clinic (optional)</label>
            <div class=" searchPatientText mt-2 mb-2">
            <div style="margin-right: 5px;">
            <input type="text" id="mobile" maxlength="10" v-model="clinicShortName" name="mobile" class="form-control"   />
            </div>
            <div class=" searchBtn" id="patient_list">
            <span>
            <button type="button" @click="updateClinic()" :disabled="selected == '1' || !clinicShortName" class="btn btn-blue-color text-white"
            >Submit</button>
            </span>
            </div>
            </div>
        </div>
      </div>
      <div class="hr-border"></div>
      <div  v-if="selected != '1' ">

        <!-- <router-link to="/doctor/clinic/team"> -->
              <div  class="SettingName text-color" >Team</div>
        <!-- </router-link> -->
      <div class="SettingName my-2 text-color" style="font-size:20px;cursor: auto;">{{ this.clinicData[0].admin_customer_id.companyShortName}}</div>
      </div>
  </div>
</div>
<doctorfooter></doctorfooter>
</template>

<script>
import doctorfooter from "./doctor-footer.vue";
import axios from 'axios';
export default {
components: {
    doctorfooter
},
data() {
    return {
    
        doctorData:{},
        clinicData:[],
        selected:'1',
        allCustomers:[],
        clinicShortName:'',
        doctorClinicDataObj:{},
        doctorClinicDataArray:[]
    }
},
created: async function () {
    let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);
           if(this.DoctorId){
     await this.getDoctorsAndClinic();
    await  this.getCustomer();     } 
},
computed: {
},
methods: {
    updateClinic(){
      console.log(this.selected);
      if(this.selected?.id){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    const payload={
        short_name:this.clinicShortName
    }
    axios
          .put(`${BASE_API_URL}/clinic-consultation/${this.selected.id}/update`, payload)
          .then((response) => {
            window.location.reload()
            return response.data
          })
        }
    },

    async getDoctorsAndClinic() {
  try {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    
    // Fetch doctor data
    const doctorResponse = await axios.get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`);
    this.doctorData = doctorResponse.data;

    if (this.doctorData) {
      // Fetch clinic data
      const clinicResponse = await axios.get(`${BASE_API_URL}/clinic-consultation`);
      this.clinicData = clinicResponse.data.filter((data) => {
        return (
          data?.id == this.doctorData?.clinic_hcp_id ||
          data?.id == this.doctorData?.clinic_admin_id||
          data?.hcps_id?.id == this.doctorData?.id
        );
      });
    }
  } catch (error) {
    console.error("An error occurred while fetching data:", error);
  }
},
async getCustomer(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/admincustomers`)
    .then(response => {
        response.data.map((data)=>{
        const users = JSON.stringify(data.users);
        JSON.parse(users)
          this.allCustomers.push(data)
          })
    })
    },
 
}
};
</script>
<style>
.SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
.searchBtn{
display: flex;
justify-content: flex-start;
}
.searchPatientText {
margin-top: 0rem;
display: flex;
justify-content: flex-start;
}
.consultation-select{
width: 400px;
font-size: 20px;
margin-left: auto;
margin-right: auto;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  
  .mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
</style>